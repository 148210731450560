import {
  DashboardOutlined,
  TeamOutlined,
  TagsOutlined,
  SolutionOutlined,
  SettingOutlined,
  ClusterOutlined,
  DeploymentUnitOutlined,
  FundProjectionScreenOutlined,
  PieChartOutlined,
  StockOutlined, GoldOutlined,
  ForkOutlined, ControlOutlined, CarOutlined, MessageOutlined, AreaChartOutlined, EnvironmentOutlined, ReconciliationOutlined, KeyOutlined, CheckSquareOutlined
} from '@ant-design/icons';
import {CAN_VIEW_MODULE, WEB_CONFIG} from "./AppConfig";

let dashBoardNavTree = [{
  key: 'dashboard',
  path: '/app/dashboard',
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [{
    key: 'dashboard-default',
    path: '/app/dashboard',
    title: 'sidenav.dashboard.default',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: []
  }]
}]

if(CAN_VIEW_MODULE(131)){
  dashBoardNavTree[0].submenu.push({
    key: 'dashboard-map',
    path: '/app/interactive',
    title: 'sidenav.dashboard.interactive',
    icon: EnvironmentOutlined,
    breadcrumb: false,
    submenu: []
  })
}
if(CAN_VIEW_MODULE(1)){
  dashBoardNavTree[0].submenu.push({
    key: 'dashboard-settings',
    path: '/app/settings',
    title: 'sidenav.dashboard.settings',
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: []
  })
}
if(CAN_VIEW_MODULE(5)){
  dashBoardNavTree[0].submenu.push({
    key: 'dashboard-staff',
    path: '/app/staff/list',
    title: 'sidenav.staff',
    icon: TeamOutlined,
    breadcrumb: true,
    submenu: []
  })
}
if(CAN_VIEW_MODULE(138)){
  dashBoardNavTree[0].submenu.push({
    key: 'dashboard-auth-control',
    path: '/app/authcontrol',
    title: 'sidenav.auth',
    icon: KeyOutlined,
    breadcrumb: true,
    submenu: []
  })
}
if(CAN_VIEW_MODULE(8)){
  dashBoardNavTree[0].submenu.push({
    key: 'dashboard-vehicles',
    path: '/app/vehicles/list',
    title: 'sidenav.vehicles',
    icon: CarOutlined,
    breadcrumb: true,
    submenu: []
  })
}
if(CAN_VIEW_MODULE(10)){
  dashBoardNavTree[0].submenu.push({
    key: 'dashboard-districts',
    path: '/app/districts/list',
    title: 'sidenav.districts',
    icon: DeploymentUnitOutlined,
    breadcrumb: true,
    submenu: []
  })
}
if(CAN_VIEW_MODULE(13)){
  dashBoardNavTree[0].submenu.push({
    key: 'dashboard-zones',
    path: '/app/zones/list',
    title: 'sidenav.zones',
    icon: ForkOutlined,
    breadcrumb: true,
    submenu: []
  })
}
if(CAN_VIEW_MODULE(147)){
  dashBoardNavTree[0].submenu.push({
    key: 'dashboard-dyn-remarks',
    path: '/app/remarks/list',
    title: 'sidenav.remarks',
    icon: CheckSquareOutlined,
    breadcrumb: true,
    submenu: []
  })
}
if(CAN_VIEW_MODULE(155)){
  dashBoardNavTree[0].submenu.push({
    key: 'dashboard-dyn-reports',
    path: '/app/dyn_reports/list',
    title: 'sidenav.dynreports',
    icon: ControlOutlined,
    breadcrumb: true,
    submenu: []
  })
}

/*{
  key: 'dashboard-newsletter',
  path: '/app/newsletter/inbox',
  title: 'sidenav.newsletter',
  icon: SendOutlined,
  breadcrumb: true,
  submenu: []
},*/

let ticketsNavTree = [{
  key: 'tickets',
  path: '/app/tickets',
  title: 'sidenav.tickets',
  icon: TagsOutlined,
  breadcrumb: true,
  submenu: []
}]

if(CAN_VIEW_MODULE(2)){
  ticketsNavTree[0].submenu.push({
    key: 'tickets-list',
    path: '/app/tickets/list',
    title: 'sidenav.tickets',
    icon: TagsOutlined,
    breadcrumb: false,
    submenu: []
  })
}
if(CAN_VIEW_MODULE(106)){
  ticketsNavTree[0].submenu.push({
    key: 'mission-list',
    path: '/app/mission/sheet',
    title: 'sidenav.mission',
    icon: ReconciliationOutlined,
    breadcrumb: false,
    submenu: []
  })
}
if(CAN_VIEW_MODULE(6)){
  ticketsNavTree[0].submenu.push({
    key: 'tickets-customers',
    path: '/app/customers/list',
    title: 'sidenav.customers',
    icon: SolutionOutlined,
    breadcrumb: true,
    submenu: []
  })
}
if(CAN_VIEW_MODULE(9)){
  ticketsNavTree[0].submenu.push({
    key: 'tickets-equipments',
    path: '/app/equipments/list',
    title: 'sidenav.equipments',
    icon: GoldOutlined,
    breadcrumb: true,
    submenu: []
  })
}
if(CAN_VIEW_MODULE(7)){
  ticketsNavTree[0].submenu.push({
    key: 'tickets-stock',
    path: '/app/stock/list',
    title: 'sidenav.stock',
    icon: ClusterOutlined,
    breadcrumb: true,
    submenu: []
  })
}
if(CAN_VIEW_MODULE(3)){
  ticketsNavTree[0].submenu.push({
    key: 'tickets-chat',
    path: '/app/chat',
    title: 'sidenav.chat',
    icon: MessageOutlined,
    breadcrumb: true,
    submenu: []
  })
}

const reportsNavTree = [{
  key: 'reports',
  path: '/app/reports',
  title: 'sidenav.reports',
  icon: AreaChartOutlined,
  breadcrumb: false,
  submenu: []
}]

if(CAN_VIEW_MODULE(104)){
  reportsNavTree[0].submenu.push({
    key: 'reports-tickets',
    path: '/app/reports/tickets',
    title: 'sidenav.reports-tickets',
    icon: PieChartOutlined,
    breadcrumb: false,
    submenu: []
  })
}
if(CAN_VIEW_MODULE(102)){
  reportsNavTree[0].submenu.push({
    key: 'reports-staff',
    path: '/app/reports/staff',
    title: 'sidenav.reports-staff',
    icon: AreaChartOutlined,
    breadcrumb: false,
    submenu: []
  })
}
if(CAN_VIEW_MODULE(105)){
  reportsNavTree[0].submenu.push({
    key: 'reports-customers',
    path: '/app/reports/customers',
    title: 'sidenav.reports-customers',
    icon: FundProjectionScreenOutlined,
    breadcrumb: false,
    submenu: []
  })
}
if(CAN_VIEW_MODULE(103)){
  reportsNavTree[0].submenu.push({
    key: 'reports-stock',
    path: '/app/reports/stock',
    title: 'sidenav.reports-stock',
    icon: StockOutlined,
    breadcrumb: false,
    submenu: []
  })
}

let navigationConfig

if(WEB_CONFIG("role_id") === 10) {
  navigationConfig = [...dashBoardNavTree]
}
else {
  navigationConfig = [...dashBoardNavTree, ...ticketsNavTree, ...reportsNavTree]
}

export default navigationConfig;
