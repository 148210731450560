
const dev = {
  API_ENDPOINT_URL: 'https://falsom.detailslocal.com/api',
  SITE_URL: 'https://falsom.detailslocal.com',
  WS_SOCKET_URL: 'wss://detailslocal.com:3000',
  WS_SOCKET_PORT: 3000,
};

const prod_dh = {
  API_ENDPOINT_URL: 'https://api.arabianfal.net/api',
	SITE_URL: 'https://api.arabianfal.net',
	WS_SOCKET_URL: 'wss://arabianfal.net:3000',
	WS_SOCKET_PORT: 3000,
};

const prod_ud = {
  API_ENDPOINT_URL: 'https://udapi.arabianfal.net/api',
	SITE_URL: 'https://udapi.arabianfal.net',
	WS_SOCKET_URL: 'wss://arabianfal.net:4000',
	WS_SOCKET_PORT: 4000,
};

const prod_rt = {
  API_ENDPOINT_URL: 'https://rtapi.arabianfal.net/api',
	SITE_URL: 'https://rtapi.arabianfal.net',
	WS_SOCKET_URL: 'wss://arabianfal.net:4500',
	WS_SOCKET_PORT: 4500,
};

const test = {
  API_ENDPOINT_URL: 'https://staging-api.arabianfal.net/api',
	SITE_URL: 'https://staging-api.arabianfal.net',
	WS_SOCKET_URL: 'wss://arabianfal.net:3001',
	WS_SOCKET_PORT: 3001,
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			const subdomain = window.location.hostname.split('.').slice(0, -2).join('.');
			console.log("subdomain detected:", subdomain)
			switch (subdomain) {
				case 'staging':
					return test
				case 'dh':
					return prod_dh
				case 'ud':
					return prod_ud
				case 'dh-indust':
					return prod_ud
				case 'rt':
					return prod_rt
				default:
					break;
			}
			break;
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
